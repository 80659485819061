import { t } from "i18next";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GridTable } from "../functions";

import { updateLoader } from "../redux/statSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileCsv } from "@fortawesome/free-solid-svg-icons";

const formatDate = (date) => date.toISOString().split("T")[0];

export const Clicks = () => {
    const dispatch = useDispatch()

    const statDataRedux = useSelector((state) => state.stat)

    const [dateFrom, setDateFrom] = useState(formatDate(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)));
    const [dateTo, setDateTo] = useState(formatDate(new Date()));
    const [statData, setStatData] = useState([]);
    const [dateRange, setDateRange] = useState([]);
    const [oneThree, setOneThree] = useState("one");

    useEffect(() => {
        const generateDateRange = (start, end) => {
            const startDate = new Date(start);
            const endDate = new Date(end);
            const dates = [];

            while (startDate <= endDate) {
                dates.push(formatDate(new Date(startDate)));
                startDate.setDate(startDate.getDate() + 1);
            }

            return dates;
        };

        setDateRange(generateDateRange(dateFrom, dateTo));
    }, [dateFrom, dateTo]);

    useEffect(() => {
        dispatch(updateLoader(true));
        const fetchData = async () => {
            try {
                const response = await fetch(
                    `https://spaceshipbridge.com/ud/api.php?action=getcloakstat&server=${localStorage.getItem('server')}&from=${dateFrom}&to=${dateTo}&user=${localStorage.getItem("user.id")}`
                );
                const json = await response.json();
                json.data && setStatData(json.data);
            } catch (error) {
                console.error("Error fetching data:", error);
                dispatch(updateLoader(false));
            } finally {
                dispatch(updateLoader(false));
            }
        };

        fetchData();
    }, [dateFrom, dateTo, dispatch]);

    const downloadCSV = () => {
        const csvData = [];
        const headers = ["Name", ...dateRange.flatMap((date) => (oneThree === "one" ? [date] : [`${date} Passed`, `${date} Blocked`, `${date} Total`]))];
        csvData.push(headers.join(","));

        statData.forEach((item) => {
            const row = [item.name];
            dateRange.forEach((date) => {
                const dateStats = item.clicks[date] || { passed: 0, blocked: 0, total: 0 };
                if (oneThree === "one") {
                    row.push(`${dateStats.passed}/${dateStats.blocked}/${dateStats.total}`);
                } else {
                    row.push(dateStats.passed, dateStats.blocked, dateStats.total);
                }
            });
            csvData.push(row.join(","));
        });

        const blob = new Blob([csvData.join("\n")], { type: "text/csv;charset=utf-8;" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = `statistics_${dateFrom}_${dateTo}.csv`;
        link.click();
    };

    return (
        <>
            <h1>{t("cloak.header.clicks")}</h1>

            <div className="stat_navigation">
                <div className="btn-group">
                    <button
                        className={["btn", oneThree === "one" ? "btn-primary" : "btn-default"].join(" ")}
                        onClick={() => setOneThree("one")}
                    >
                        {t("cloak.stat.onecolumn")}
                    </button>
                    <button
                        className={["btn", oneThree === "three" ? "btn-primary" : "btn-default"].join(" ")}
                        onClick={() => setOneThree("three")}
                    >
                        {t("cloak.stat.threecolumns")}
                    </button>
                </div>
                <button className="btn btn-info" onClick={downloadCSV}>
                    <FontAwesomeIcon icon={faFileCsv} /> {t("cloak.stat.downloadcsv")}
                </button>
                <div className="btn-group clicks_dates">
                    <input
                        className="form-control"
                        type="date"
                        value={dateFrom}
                        onChange={(e) => setDateFrom(e.target.value)}
                    />
                    <input
                        className="form-control"
                        type="date"
                        value={dateTo}
                        onChange={(e) => setDateTo(e.target.value)}
                    />
                    <button className="btn btn-primary" onClick={() => {}}>
                        {t("cloak.stat.show")}
                    </button>
                </div>
            </div>

            {statDataRedux.loading
            ?
            <GridTable rows={10} columns={9} />
            :
            <div className="table_horizontal_wrapper">
                <table className={["table", oneThree].join(" ")}>
                    <thead>
                        <tr>
                            <th>{t("cloak.cloaks.name")}</th>
                            {oneThree === "one" &&
                                dateRange.map((date, index) => <th key={`statheadertr${index}`}>{date}</th>)}
                            {oneThree === "three" &&
                                dateRange.map((date, index) => (
                                    <th key={`statheadertr${index}`} colSpan="3">
                                        {date}
                                    </th>
                                ))}
                        </tr>
                    </thead>
                    <tbody>
                        {statData.map((item, i) => (
                            <tr key={`stattr${i}`}>
                                <td>{item.name}</td>
                                {oneThree === "one" &&
                                    dateRange.map((date, j) => {
                                        const dateStats = item.clicks[date] || { passed: 0, blocked: 0, total: 0 };
                                        return (
                                            <td key={`statdatatr${i}-${j}`}>
                                                <span className="green">{dateStats.passed}</span>{" "}
                                                <span className="grey">/</span>{" "}
                                                <span className="red">{dateStats.blocked}</span>{" "}
                                                <span className="grey">/</span> <span>{dateStats.total}</span>
                                            </td>
                                        );
                                    })}
                                {oneThree === "three" &&
                                    dateRange.map((date, j) => {
                                        const dateStats = item.clicks[date] || { passed: 0, blocked: 0, total: 0 };
                                        return (
                                            <>
                                                <td key={`statdatatr${i}-${j}1`} className="green">
                                                    {dateStats.passed}
                                                </td>
                                                <td key={`statdatatr${i}-${j}2`} className="red">
                                                    {dateStats.blocked}
                                                </td>
                                                <td key={`statdatatr${i}-${j}3`}>{dateStats.total}</td>
                                            </>
                                        );
                                    })}
                            </tr>
                        ))}
                        <tr>
                            <td>
                                <strong>{t("cloak.header.total")}</strong>
                            </td>
                            {oneThree === "one" &&
                                dateRange.map((date, index) => {
                                const totalForDate = statData.reduce(
                                    (acc, item) => {
                                        const dateStats = item.clicks[date] || { passed: 0, blocked: 0, total: 0 };
                                        acc.passed += dateStats.passed;
                                        acc.blocked += dateStats.blocked;
                                        acc.total += dateStats.total;
                                        return acc;
                                    },
                                    { passed: 0, blocked: 0, total: 0 }
                                );
                                return (
                                    <td key={`totaldatatr${index}`}>
                                        <strong className="green">{totalForDate.passed}</strong>{" "}
                                        <span className="grey">/</span>{" "}
                                        <strong className="red">{totalForDate.blocked}</strong>{" "}
                                        <span className="grey">/</span>{" "}
                                        <strong>{totalForDate.total}</strong>
                                    </td>
                                );
                            })}
                        </tr>
                    </tbody>
                </table>
            </div>
            }
        </>
    );
}