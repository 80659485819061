export const Changelog = () => {
    return (
        <>
            <h1>Changelog</h1>

            <h2>26 марта 2025</h2>
            <ul>
                <li>Добавлена фильтрация по пользовательским чёрным спискам IP адресов</li>
                <li>Добавлен белый список IP</li>
            </ul>
            
            <h2>4 февраля 2025</h2>
            <ul>
                <li>Добавлена возможность выбора часового пояска для отображения статистики по кликам</li>
            </ul>

            <h2>7 января 2025</h2>
            <ul>
                <li>Статистика кликов по клоакам за заданный диапазон</li>
                <li>Экспорт данных в CSV</li>
            </ul>

            <h2>17 декабря 2024</h2>
            <ul>
                <li>Добавлена поддержка английского языка интерфейса</li>
                <li>Добавление кастомного прелоадера</li>
            </ul>

            <h2>12 декабря 2024</h2>
            <ul>
                <li>Смена встраивания JS кода клоаки</li>
                <li>Смена ендпоинта на Python</li>
            </ul>

            <h2>13 ноября 2024</h2>
            <ul>
                <li>Сплит клоаки по Whate и странам</li>
                <li>Обязательные GET переменные для отрабатывания клоаки</li>
                <li>5 разных вариантов кода для добавления на сайт</li>
                <li>Добавление в статистику White и Black</li>
            </ul>

            <h2>5 ноября 2024</h2>
            <ul>
                <li>Обход блокировщиков рекламы и антидетект расширений браузеров</li>
            </ul>

            <h2>4 ноября 2024</h2>
            <ul>
                <li>Добавлен <strong>Linux</strong> в статистику и в детект</li>
                <li>Добавлен <strong>Chrome OS</strong> в статистику и в детект</li>
            </ul>

            <h2>31 октября 2024</h2>
            <ul>
                <li>Детект неправильного White + запись в статистику причину блокировки (wrong white)</li>
            </ul>

            <h2>30 октября 2024</h2>
            <ul>
                <li>Передача всех GET параметров из URL на Black при редиректе</li>
            </ul>
        </>
    )
}