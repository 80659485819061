import screen3 from './../screenshots/step3.png'
import screen4 from './../screenshots/step4.png'
import screen5 from './../screenshots/step5.png'
import screen56 from './../screenshots/step56.jpg'
import screen7 from './../screenshots/step7.png'
import screen8 from './../screenshots/step8.jpg'
import screen9 from './../screenshots/step9.jpg'
import screen10 from './../screenshots/step10.jpg'
import screen11 from './../screenshots/step11.jpg'
import screen12 from './../screenshots/step12.jpg'
import screen13 from './../screenshots/step13.jpg'
import screen14 from './../screenshots/step14.jpg'
import screen15 from './../screenshots/step15.jpg'
import screen16 from './../screenshots/step16.jpg'
import screen17 from './../screenshots/step17.jpg'
import screen19 from './../screenshots/step19.png'
import screen20 from './../screenshots/step20.jpg'
import screen21 from './../screenshots/step21.jpg'
import screen22 from './../screenshots/step22.jpg'
import screen23 from './../screenshots/step23.jpg'
import screen24 from './../screenshots/step24.jpg'

export const HelpRu = () => {
    return (
        <>
            <h1>Помощь</h1>

{/*
            <ul className="list">
                <li><a href="/help#cloackeditting">Editing Cloack</a></li>
                <li><a href="/help#howtoplace">How to place cloack code?</a></li>
            </ul>
*/}

            <h2>Получение пароля и ссылки на авторизацию</h2>
            <p>Чтобы воспользоваться данным приложением, необходимо получить пароль и ссылку на страницу авторизации, написав письмо в телеграм <a href="https://t.me/sales_knk">@sales_knk</a> в произвольной форме.</p>

            <h2>Переход на страницу авторизации</h2>
            <p>Переходим по ссылке на страницу авторизации.</p>

            <h2>Ввод Email</h2>
            <p>В поле <strong>Email</strong> вводим свой email (см. скрин).</p>
            <p><img src={screen3} alt="Ввод Email" className="img_wide_mobile" /></p>

            <h2>Ввод пароля</h2>
            <p>В поле <strong>Password</strong> вводим полученный от нас пароль (см. скрин).</p>
            <p><img src={screen4} alt="" className="img_wide_mobile" /></p>

            <h2>Нажатие кнопки "Авторизация"</h2>
            <p>Нажимаем кнопку <strong>«Авторизация»</strong> (см. скрин).</p>
            <p><img src={screen5} alt="" className="img_wide_mobile" /></p>

            <h2>Часовой пояс</h2>
            <p>Проверьте и настройте удобный для вас часовой пояс в кабинете. Для этого перейдите во вкладку "Аккаунт" и установите нужный часовой пояс.</p>
            <p><img src={screen56} alt="" className="img_wide_desktop" /></p>

            <h2>Открытие страницы с перечнем клоак</h2>
            <p>Открывается страница с перечнем клоак.</p>

            <h2>Добавление новой клоаки</h2>
            <p>Нажимаем кнопку <strong>“Add Cloak”</strong> (см. скрин).</p>
            <p><img src={screen7} alt="" className="img_wide_mobile" /></p>

            <h2>Ввод названия клоаки</h2>
            <p>В открывшемся окне в поле <strong>Cloak Name</strong> вводим рандомное название клоаки (см. скрин).</p>
            <p><img src={screen8} alt="" className="img_wide_mobile" /></p>

            <h2>Добавление White URL</h2>
            <p>В поле <strong>White URL</strong> добавляем адрес белого сайта, из которого будет идти редирект, в формате <b>https://example1.com/</b> (см. скрин).</p>
            <p><img src={screen9} alt="" className="img_wide_mobile" /></p>

            <h2>Добавление Black URL</h2>
            <p>В поле <strong>Black URL</strong> добавляем адрес серого сайта, на который будет идти редирект, в формате <b>https://example2.com/</b> (см. скрин).</p>
            <p><img src={screen10} alt="" className="img_wide_mobile" /></p>

            <h2>Подтверждение добавления клоаки</h2>
            <p>Нажимаем кнопку <strong>Add Cloak</strong> (см. скрин).</p>
            <p><img src={screen11} alt="" className="img_wide_mobile" /></p>

            <h2>Настройка клоаки</h2>
            <p>Открывается страница настройки клоаки. Поля: <strong>Cloak Name</strong>, <strong>White URL</strong>, <strong>Black
                    URL</strong> должны быть заполнены данными, которые введены согласно пунктам 8-10 (см. скрин).</p>
            <p><img src={screen12} alt="" className="img_wide_desktop" /></p>

            <h2>Указание стран для рекламы</h2>
            <p>На странице настройки в поле <strong>Countries</strong> вводим все коды стран через запятую, на которые настроена
                реклама для белого сайта (см. скрин). Пользователи из всех остальных стран будут отправлены на white page.</p>
            <p><img src={screen13} alt="" className="img_wide_desktop" /></p>
            <table className="help_table">
                <thead>
                <tr>
                    <th>Country</th>
                    <th>Code</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>Afghanistan</td>
                    <td>AF</td>
                </tr>
                <tr>
                    <td>Albania</td>
                    <td>AL</td>
                </tr>
                <tr>
                    <td>Algeria</td>
                    <td>DZ</td>
                </tr>
                <tr>
                    <td>Andorra</td>
                    <td>AD</td>
                </tr>
                <tr>
                    <td>Angola</td>
                    <td>AO</td>
                </tr>
                <tr>
                    <td>Antigua and Barbuda</td>
                    <td>AG</td>
                </tr>
                <tr>
                    <td>Argentina</td>
                    <td>AR</td>
                </tr>
                <tr>
                    <td>Armenia</td>
                    <td>AM</td>
                </tr>
                <tr>
                    <td>Australia</td>
                    <td>AU</td>
                </tr>
                <tr>
                    <td>Austria</td>
                    <td>AT</td>
                </tr>
                <tr>
                    <td>Azerbaijan</td>
                    <td>AZ</td>
                </tr>
                <tr>
                    <td>Bahamas</td>
                    <td>BS</td>
                </tr>
                <tr>
                    <td>Bahrain</td>
                    <td>BH</td>
                </tr>
                <tr>
                    <td>Bangladesh</td>
                    <td>BD</td>
                </tr>
                <tr>
                    <td>Barbados</td>
                    <td>BB</td>
                </tr>
                <tr>
                    <td>Belarus</td>
                    <td>BY</td>
                </tr>
                <tr>
                    <td>Belgium</td>
                    <td>BE</td>
                </tr>
                <tr>
                    <td>Belize</td>
                    <td>BZ</td>
                </tr>
                <tr>
                    <td>Benin</td>
                    <td>BJ</td>
                </tr>
                <tr>
                    <td>Bhutan</td>
                    <td>BT</td>
                </tr>
                <tr>
                    <td>Bolivia</td>
                    <td>BO</td>
                </tr>
                <tr>
                    <td>Bosnia and Herzegovina</td>
                    <td>BA</td>
                </tr>
                <tr>
                    <td>Botswana</td>
                    <td>BW</td>
                </tr>
                <tr>
                    <td>Brazil</td>
                    <td>BR</td>
                </tr>
                <tr>
                    <td>Brunei</td>
                    <td>BN</td>
                </tr>
                <tr>
                    <td>Bulgaria</td>
                    <td>BG</td>
                </tr>
                <tr>
                    <td>Burkina Faso</td>
                    <td>BF</td>
                </tr>
                <tr>
                    <td>Burundi</td>
                    <td>BI</td>
                </tr>
                <tr>
                    <td>Cabo Verde</td>
                    <td>CV</td>
                </tr>
                <tr>
                    <td>Cambodia</td>
                    <td>KH</td>
                </tr>
                <tr>
                    <td>Cameroon</td>
                    <td>CM</td>
                </tr>
                <tr>
                    <td>Canada</td>
                    <td>CA</td>
                </tr>
                <tr>
                    <td>Central African Republic</td>
                    <td>CF</td>
                </tr>
                <tr>
                    <td>Chad</td>
                    <td>TD</td>
                </tr>
                <tr>
                    <td>Chile</td>
                    <td>CL</td>
                </tr>
                <tr>
                    <td>China</td>
                    <td>CN</td>
                </tr>
                <tr>
                    <td>Colombia</td>
                    <td>CO</td>
                </tr>
                <tr>
                    <td>Comoros</td>
                    <td>KM</td>
                </tr>
                <tr>
                    <td>Congo (Congo-Brazzaville)</td>
                    <td>CG</td>
                </tr>
                <tr>
                    <td>Costa Rica</td>
                    <td>CR</td>
                </tr>
                <tr>
                    <td>Croatia</td>
                    <td>HR</td>
                </tr>
                <tr>
                    <td>Cuba</td>
                    <td>CU</td>
                </tr>
                <tr>
                    <td>Cyprus</td>
                    <td>CY</td>
                </tr>
                <tr>
                    <td>Czechia (Czech Republic)</td>
                    <td>CZ</td>
                </tr>
                <tr>
                    <td>Denmark</td>
                    <td>DK</td>
                </tr>
                <tr>
                    <td>Djibouti</td>
                    <td>DJ</td>
                </tr>
                <tr>
                    <td>Dominica</td>
                    <td>DM</td>
                </tr>
                <tr>
                    <td>Dominican Republic</td>
                    <td>DO</td>
                </tr>
                <tr>
                    <td>Ecuador</td>
                    <td>EC</td>
                </tr>
                <tr>
                    <td>Egypt</td>
                    <td>EG</td>
                </tr>
                <tr>
                    <td>El Salvador</td>
                    <td>SV</td>
                </tr>
                <tr>
                    <td>Equatorial Guinea</td>
                    <td>GQ</td>
                </tr>
                <tr>
                    <td>Eritrea</td>
                    <td>ER</td>
                </tr>
                <tr>
                    <td>Estonia</td>
                    <td>EE</td>
                </tr>
                <tr>
                    <td>Eswatini</td>
                    <td>SZ</td>
                </tr>
                <tr>
                    <td>Ethiopia</td>
                    <td>ET</td>
                </tr>
                <tr>
                    <td>Fiji</td>
                    <td>FJ</td>
                </tr>
                <tr>
                    <td>Finland</td>
                    <td>FI</td>
                </tr>
                <tr>
                    <td>France</td>
                    <td>FR</td>
                </tr>
                <tr>
                    <td>Gabon</td>
                    <td>GA</td>
                </tr>
                <tr>
                    <td>Gambia</td>
                    <td>GM</td>
                </tr>
                <tr>
                    <td>Georgia</td>
                    <td>GE</td>
                </tr>
                <tr>
                    <td>Germany</td>
                    <td>DE</td>
                </tr>
                <tr>
                    <td>Ghana</td>
                    <td>GH</td>
                </tr>
                <tr>
                    <td>Greece</td>
                    <td>GR</td>
                </tr>
                <tr>
                    <td>Grenada</td>
                    <td>GD</td>
                </tr>
                <tr>
                    <td>Guatemala</td>
                    <td>GT</td>
                </tr>
                <tr>
                    <td>Guinea</td>
                    <td>GN</td>
                </tr>
                <tr>
                    <td>Guinea-Bissau</td>
                    <td>GW</td>
                </tr>
                <tr>
                    <td>Guyana</td>
                    <td>GY</td>
                </tr>
                <tr>
                    <td>Haiti</td>
                    <td>HT</td>
                </tr>
                <tr>
                    <td>Honduras</td>
                    <td>HN</td>
                </tr>
                <tr>
                    <td>Hungary</td>
                    <td>HU</td>
                </tr>
                <tr>
                    <td>Iceland</td>
                    <td>IS</td>
                </tr>
                <tr>
                    <td>India</td>
                    <td>IN</td>
                </tr>
                <tr>
                    <td>Indonesia</td>
                    <td>ID</td>
                </tr>
                <tr>
                    <td>Iran</td>
                    <td>IR</td>
                </tr>
                <tr>
                    <td>Iraq</td>
                    <td>IQ</td>
                </tr>
                <tr>
                    <td>Ireland</td>
                    <td>IE</td>
                </tr>
                <tr>
                    <td>Israel</td>
                    <td>IL</td>
                </tr>
                <tr>
                    <td>Italy</td>
                    <td>IT</td>
                </tr>
                <tr>
                    <td>Jamaica</td>
                    <td>JM</td>
                </tr>
                <tr>
                    <td>Japan</td>
                    <td>JP</td>
                </tr>
                <tr>
                    <td>Jordan</td>
                    <td>JO</td>
                </tr>
                <tr>
                    <td>Kazakhstan</td>
                    <td>KZ</td>
                </tr>
                <tr>
                    <td>Kenya</td>
                    <td>KE</td>
                </tr>
                <tr>
                    <td>Kiribati</td>
                    <td>KI</td>
                </tr>
                <tr>
                    <td>Kuwait</td>
                    <td>KW</td>
                </tr>
                <tr>
                    <td>Kyrgyzstan</td>
                    <td>KG</td>
                </tr>
                <tr>
                    <td>Laos</td>
                    <td>LA</td>
                </tr>
                <tr>
                    <td>Latvia</td>
                    <td>LV</td>
                </tr>
                <tr>
                    <td>Lebanon</td>
                    <td>LB</td>
                </tr>
                <tr>
                    <td>Lesotho</td>
                    <td>LS</td>
                </tr>
                <tr>
                    <td>Liberia</td>
                    <td>LR</td>
                </tr>
                <tr>
                    <td>Libya</td>
                    <td>LY</td>
                </tr>
                <tr>
                    <td>Liechtenstein</td>
                    <td>LI</td>
                </tr>
                <tr>
                    <td>Lithuania</td>
                    <td>LT</td>
                </tr>
                <tr>
                    <td>Luxembourg</td>
                    <td>LU</td>
                </tr>
                <tr>
                    <td>Madagascar</td>
                    <td>MG</td>
                </tr>
                <tr>
                    <td>Malawi</td>
                    <td>MW</td>
                </tr>
                <tr>
                    <td>Malaysia</td>
                    <td>MY</td>
                </tr>
                <tr>
                    <td>Maldives</td>
                    <td>MV</td>
                </tr>
                <tr>
                    <td>Mali</td>
                    <td>ML</td>
                </tr>
                <tr>
                    <td>Malta</td>
                    <td>MT</td>
                </tr>
                <tr>
                    <td>Marshall Islands</td>
                    <td>MH</td>
                </tr>
                <tr>
                    <td>Mauritania</td>
                    <td>MR</td>
                </tr>
                <tr>
                    <td>Mauritius</td>
                    <td>MU</td>
                </tr>
                <tr>
                    <td>Mexico</td>
                    <td>MX</td>
                </tr>
                <tr>
                    <td>Micronesia</td>
                    <td>FM</td>
                </tr>
                <tr>
                    <td>Moldova</td>
                    <td>MD</td>
                </tr>
                <tr>
                    <td>Monaco</td>
                    <td>MC</td>
                </tr>
                <tr>
                    <td>Mongolia</td>
                    <td>MN</td>
                </tr>
                <tr>
                    <td>Montenegro</td>
                    <td>ME</td>
                </tr>
                <tr>
                    <td>Morocco</td>
                    <td>MA</td>
                </tr>
                <tr>
                    <td>Mozambique</td>
                    <td>MZ</td>
                </tr>
                <tr>
                    <td>Myanmar (Burma)</td>
                    <td>MM</td>
                </tr>
                <tr>
                    <td>Namibia</td>
                    <td>NA</td>
                </tr>
                <tr>
                    <td>Nauru</td>
                    <td>NR</td>
                </tr>
                <tr>
                    <td>Nepal</td>
                    <td>NP</td>
                </tr>
                <tr>
                    <td>Netherlands</td>
                    <td>NL</td>
                </tr>
                <tr>
                    <td>New Zealand</td>
                    <td>NZ</td>
                </tr>
                <tr>
                    <td>Nicaragua</td>
                    <td>NI</td>
                </tr>
                <tr>
                    <td>Niger</td>
                    <td>NE</td>
                </tr>
                <tr>
                    <td>Nigeria</td>
                    <td>NG</td>
                </tr>
                <tr>
                    <td>North Macedonia</td>
                    <td>MK</td>
                </tr>
                <tr>
                    <td>Norway</td>
                    <td>NO</td>
                </tr>
                <tr>
                    <td>Oman</td>
                    <td>OM</td>
                </tr>
                <tr>
                    <td>Pakistan</td>
                    <td>PK</td>
                </tr>
                <tr>
                    <td>Palau</td>
                    <td>PW</td>
                </tr>
                <tr>
                    <td>Panama</td>
                    <td>PA</td>
                </tr>
                <tr>
                    <td>Papua New Guinea</td>
                    <td>PG</td>
                </tr>
                <tr>
                    <td>Paraguay</td>
                    <td>PY</td>
                </tr>
                <tr>
                    <td>Peru</td>
                    <td>PE</td>
                </tr>
                <tr>
                    <td>Philippines</td>
                    <td>PH</td>
                </tr>
                <tr>
                    <td>Poland</td>
                    <td>PL</td>
                </tr>
                <tr>
                    <td>Portugal</td>
                    <td>PT</td>
                </tr>
                <tr>
                    <td>Qatar</td>
                    <td>QA</td>
                </tr>
                <tr>
                    <td>Romania</td>
                    <td>RO</td>
                </tr>
                <tr>
                    <td>Russia</td>
                    <td>RU</td>
                </tr>
                <tr>
                    <td>Rwanda</td>
                    <td>RW</td>
                </tr>
                <tr>
                    <td>Saint Kitts and Nevis</td>
                    <td>KN</td>
                </tr>
                <tr>
                    <td>Saint Lucia</td>
                    <td>LC</td>
                </tr>
                <tr>
                    <td>Saint Vincent and the Grenadines</td>
                    <td>VC</td>
                </tr>
                <tr>
                    <td>Samoa</td>
                    <td>WS</td>
                </tr>
                <tr>
                    <td>San Marino</td>
                    <td>SM</td>
                </tr>
                <tr>
                    <td>Sao Tome and Principe</td>
                    <td>ST</td>
                </tr>
                <tr>
                    <td>Saudi Arabia</td>
                    <td>SA</td>
                </tr>
                <tr>
                    <td>Senegal</td>
                    <td>SN</td>
                </tr>
                <tr>
                    <td>Serbia</td>
                    <td>RS</td>
                </tr>
                <tr>
                    <td>Seychelles</td>
                    <td>SC</td>
                </tr>
                <tr>
                    <td>Sierra Leone</td>
                    <td>SL</td>
                </tr>
                <tr>
                    <td>Singapore</td>
                    <td>SG</td>
                </tr>
                <tr>
                    <td>Slovakia</td>
                    <td>SK</td>
                </tr>
                <tr>
                    <td>Slovenia</td>
                    <td>SI</td>
                </tr>
                <tr>
                    <td>Solomon Islands</td>
                    <td>SB</td>
                </tr>
                <tr>
                    <td>Somalia</td>
                    <td>SO</td>
                </tr>
                <tr>
                    <td>South Africa</td>
                    <td>ZA</td>
                </tr>
                <tr>
                    <td>South Sudan</td>
                    <td>SS</td>
                </tr>
                <tr>
                    <td>Spain</td>
                    <td>ES</td>
                </tr>
                <tr>
                    <td>Sri Lanka</td>
                    <td>LK</td>
                </tr>
                <tr>
                    <td>Sudan</td>
                    <td>SD</td>
                </tr>
                <tr>
                    <td>Suriname</td>
                    <td>SR</td>
                </tr>
                <tr>
                    <td>Sweden</td>
                    <td>SE</td>
                </tr>
                <tr>
                    <td>Switzerland</td>
                    <td>CH</td>
                </tr>
                <tr>
                    <td>Syria</td>
                    <td>SY</td>
                </tr>
                <tr>
                    <td>Taiwan</td>
                    <td>TW</td>
                </tr>
                <tr>
                    <td>Tajikistan</td>
                    <td>TJ</td>
                </tr>
                <tr>
                    <td>Tanzania</td>
                    <td>TZ</td>
                </tr>
                <tr>
                    <td>Thailand</td>
                    <td>TH</td>
                </tr>
                <tr>
                    <td>Timor-Leste</td>
                    <td>TL</td>
                </tr>
                <tr>
                    <td>Togo</td>
                    <td>TG</td>
                </tr>
                <tr>
                    <td>Tonga</td>
                    <td>TO</td>
                </tr>
                <tr>
                    <td>Trinidad and Tobago</td>
                    <td>TT</td>
                </tr>
                <tr>
                    <td>Tunisia</td>
                    <td>TN</td>
                </tr>
                <tr>
                    <td>Turkey</td>
                    <td>TR</td>
                </tr>
                <tr>
                    <td>Turkmenistan</td>
                    <td>TM</td>
                </tr>
                <tr>
                    <td>Tuvalu</td>
                    <td>TV</td>
                </tr>
                <tr>
                    <td>Uganda</td>
                    <td>UG</td>
                </tr>
                <tr>
                    <td>Ukraine</td>
                    <td>UA</td>
                </tr>
                <tr>
                    <td>United Arab Emirates</td>
                    <td>AE</td>
                </tr>
                <tr>
                    <td>United Kingdom</td>
                    <td>GB</td>
                </tr>
                <tr>
                    <td>United States</td>
                    <td>US</td>
                </tr>
                <tr>
                    <td>Uruguay</td>
                    <td>UY</td>
                </tr>
                <tr>
                    <td>Uzbekistan</td>
                    <td>UZ</td>
                </tr>
                <tr>
                    <td>Vanuatu</td>
                    <td>VU</td>
                </tr>
                <tr>
                    <td>Vatican City</td>
                    <td>VA</td>
                </tr>
                <tr>
                    <td>Venezuela</td>
                    <td>VE</td>
                </tr>
                <tr>
                    <td>Vietnam</td>
                    <td>VN</td>
                </tr>
                <tr>
                    <td>Yemen</td>
                    <td>YE</td>
                </tr>
                <tr>
                    <td>Zambia</td>
                    <td>ZM</td>
                </tr>
                <tr>
                    <td>Zimbabwe</td>
                    <td>ZW</td>
                </tr>
                </tbody>
            </table>
                
            <h2>Указание стран для блокировки</h2>
            <p>В поле <strong>Countries Stop</strong> вводим все коды стран через запятую, для которых нужно заблокировать редирект или подмену контента. Если блокировка не нужна, оставляем поле пустым (см. скрин). Список заблокированных стран нужно использовать, если Allowed Countries пустой и вы хотите пропускать пользователей со всех стран мира, кроме указанных.</p>
            <p><img src={screen14} alt="" className="img_wide_desktop" /></p>

            <h2>Настройка задержки</h2>
            <p>В поле Cloak Delay вводим время задержки срабатывания клоаки, в мс.  По умолчанию выставляется  значение “0”. Можна вручную изменить время задержки, но рекомендуем оставить значение "0" .  При этом нужно учитывать, что для выполнения некоторых проверок необходимо дополнительное время. Например, для обнаружения эмуляторов необходимо собрать несколько дополнительных атрибутов устройства, что увеличивает время получения данных. Задержка может составлять от 300 до 1500 мс (см. скрин).</p>
            <p><img src={screen15} alt="" className="img_wide_desktop" /></p>

            <h2>Добавление ОС девайсов</h2>
            <p>Добавляем ОС девайсов (ставим галочки), которые указаны при настройке рекламы для сайта <strong>White URL</strong>. Возможные варианты: Android, iOS, Mac, Windows, Linux, ChromeOS (см. скрин). На <strong>Black URL </strong>попадут только те пользователи, которые будут заходить по рекламной ссылке на сайт из указанных девайсов (где проставлены галочки).</p>
            <p><img src={screen16} alt="" className="img_wide_desktop" /></p>

            <h2>Указание критериев проверки пользователей</h2>
            <p>Указываем критерии (ставим галочки), по которым будет выполняться проверка пользователей сайта <strong>White URL</strong> (см. скрин). Пользователи, которые не прошли проверку по указанным критериям, остаются на <strong>White URL</strong>. Например, если Вы поставили галочку VPN, и пользователь заходит по Google ссылке на <strong>White URL</strong> со включенным VPN в браузере или на мобилке, то его клоака не пропустит на <strong>Black URL</strong>. Ниже приведено описание каждого критерия проверки:</p>
            <p><img src={screen17} alt="" className="img_wide_desktop" /></p>

            <h3>VPN</h3>
            <p>Определяется, совпадает ли часовой пояс пользователя с часовым поясом исходного IP-адреса. Также предоставляется информация о том, является ли IP-адрес публичным и связан ли он с одним из известных VPN-провайдеров. Проверяется соответствие операционной системы клиента операционной системе, указанной в сетевом трафике.</p>

            <h3>Incognito</h3>
            <p>Определяется, использует ли посетитель режим инкогнито или приватный режим для доступа к веб-сайту.</p>

            <h3>Browser Bots</h3>
            <p>Получение данных о полезных и вредоносных ботах позволяет блокировать или фильтровать их из обычного трафика и предотвращать автоматизированные злоупотребления.</p>

            <h3>Browser Tampering</h3>
            <p>Существуют простые способы обхода менее сложных алгоритмов отпечатков браузера, такие как изменение User Agent или манипуляции с сигналами, собираемыми из браузера, включая использование антидетект-браузеров. Выявление попыток несанкционированного доступа помогает обнаружить это поведение и помечать посетителей, использующих подобные методы, путём сравнения сигнатуры их браузера со статистической моделью и проверки, используют ли они браузер с функцией обхода обнаружения.</p>

            <h3>Virtual Machines</h3>
            <p>Определяется, работает ли браузер в среде виртуализации, например в VirtualBox, путём анализа конфигурации браузера.
            </p>

            <h3>Privacy Settings</h3>
            <p>Браузеры, такие как Firefox, Brave и другие, ориентированные на конфиденциальность, активно противодействуют снятию отпечатков и предоставляют настройки, которые могут рандомизировать и скрывать выходные данные. Определяется, активированы ли такие настройки, и выводится соответствующий сигнал.</p>

            <h3>Developer Tools</h3>
            <p>Проверяется, открыты ли инструменты разработчика вручную в браузерах Chrome или Firefox.</p>

            <h2>Выбор типа отображения black page</h2>
            <p>Выбираем из списка <strong>"With Redirect"</strong>, если нужен редирект, или <strong>"In Frame"</strong>, 
                если нужна подмена контента (см. скрин). При использовании типа отображения black page “in frame” black page 
                должна находиться на том же домене, что и white page. Этот тип отображения black page показывает black page без 
                изменения url в адресной строке. При использовании типа отображения black page “With Redirect ” пользователь 
                будет перенаправлен на black page с помощью обычного редиректа, поэтому можно использовать black page на любом домене.</p>
            <p><img src={screen19} alt="" className="img_wide_desktop" /></p>

            <h2>Вставка кода на сайт</h2>
            <p>Копируем  код, что указан в конце страницы на черном фоне и добавляем его на сайте (из которого будет идти редирект) после открывающегося тега &lt;head&gt; (см. скрин).</p>
            <p><img src={screen20} alt="" className="img_wide_desktop" /></p>


            <h2>GET параметры</h2>
            <p> Также добавили возможность дополнительной настройки Get параметров. Если, например, у Вас настроена реклама на разные страны, и в одной стране идентификатор клика gclid, а в другой - wbraid, тогда выбираем "Учитывать один из GET параметров" (см. скрин). В этому случае клоака будет пропускать пользователей для этих двух стран с разными идентификаторами клика.</p>
            <p><img src={screen21} alt="" className="img_wide_desktop" /></p>

            <h2>Split</h2>
            <p>У нас также есть возможность настройки сплита по разным странам с разными black (см. скрин). При этом эти страны должны быть указаны в разрешенных.</p>
            <p><img src={screen22} alt="" className="img_wide_desktop" /></p>

            <h2>Завершение настройки</h2>
            <p>Включаем клоаку. Клоака настроена. Можно смотреть статистику (см.скрин). У нас удобный дашборд. Вы можете увидеть информацию о каждом пользователе с указанием причин блокировки. Также можете следить за обновлениями приложения в телеграм канале.</p>
            <p><img src={screen23} alt="" className="img_wide_desktop" /></p>

            <h2>Инфо по кликам</h2>
            <p>Добавлена возможность просмотра количества пропущенных, блокированных и общих кликов по каждой компании за определенный период времени. Для этого вам нужно перейти во вкладку Клики, выбрать диапазон дат и нажать кнопку Посмотреть. Также есть возможность выгрузки отчета по кликам у файл csv. Для этого нажимаем на кнопку Скачать CSV и сохраняем у себя на компьютере (см. скрин).</p>
            <p><img src={screen24} alt="" className="img_wide_desktop" /></p>
        </>
    )
}