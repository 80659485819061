import { useSelector, useDispatch } from "react-redux"
import { updateTimezone } from "../redux/statSlice"
import { daysUntil, formatDate, formatDateTime, getTimezoneOffset } from "../functions"

import { t } from "i18next"

export const Account = () => {
    const dispatch = useDispatch()
    const statData = useSelector((state) => state.stat)

    const timezoneChange = async (e) => {
        const value = e.target.value

        const requestData = {
            action: "updateuserfield",
            uuid: localStorage.getItem("user.id"),
            field_name: "timezone",
            server: localStorage.getItem('server'),
            value: value,
        }

        try {
            const response = await fetch("https://spaceshipbridge.com/ud/api.php", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(requestData),
            })

            const result = await response.json()
            result.status === 200 && dispatch(updateTimezone(value))
        } catch (error) {
            console.error("Ошибка при отправке данных:", error)
        }
    }

    return (
        <>
            <h1>{t("cloak.account.account")}</h1>
            <div className="account_expires">
                {/*
                <div className="account_expires_content">
                    {t("cloak.account.balance")}{" "}
                    <div className="account_expires_content_when">{statData.balance}</div>
                </div>
                <div className="account_expires_content">
                    {t("cloak.account.clicksleft")}{" "}
                    <div className="account_expires_content_when">{statData.clicks}</div>
                </div>
                */}
                <div className={['account_expires_content'].join(' ')}>
                    {t("cloak.account.expiresat")}{" "}
                    <div className="account_expires_content_when">{formatDate(statData.expires)}</div>
                </div>
                <div className="account_expires_content">
                    {t("cloak.account.daysleft")}{" "}
                    <div className="account_expires_content_when">{daysUntil(statData.expires)}</div>
                </div>
                <div className="account_expires_content">
                    {t("cloak.account.paycloak")}{" "}
                    <div className="account_expires_content_when">
                        <a href="https://t.me/yuliia_m_knk" target="_blank" rel="noopener noreferrer">
                            @yuliia_m_knk
                        </a>
                    </div>
                </div>
            </div>

            <select
                className="form-control timezone"
                onChange={timezoneChange}
                value={statData.timezone}
            >
                {Intl.supportedValuesOf("timeZone").map((tz) => {
                    const offset = getTimezoneOffset(tz);
                    return (
                        <option key={tz} value={tz}>
                            {tz} {offset}
                        </option>
                    );
                })}
            </select>

            <p>Email: {statData.email}</p>
            <p>{t("cloak.account.registered")}: {formatDateTime(statData.registered)}</p>

            {statData.payments.length > 0 && (
                <>
                    <h2>{t("cloak.account.payments")}</h2>
                    <table className="table">
                        <thead>
                            <tr>
                                <th>{t("cloak.account.summ")}, $</th>
                                <th>{t("cloak.account.perioddays")}</th>
                                <th>{t("cloak.account.when")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {statData.payments.map((e, i) => (
                                <tr key={`paymentstr${i}`}>
                                    <td>{e.summ}</td>
                                    <td>{e.term}</td>
                                    <td>{e.whenwas}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </>
            )}
        </>
    )
}
