import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    passed: 0,
    blocked: 0,
    total: 0,
    loading: false,
    addcloackpopup: false,
    topmenu: false,
    daysleft: 0,
    expires: '',
    registered: '',
    email: '',
    payments: '',
    balance: 0,
    clicks: 0,
    dataloaded: false,
    timezone: 'Europe/Kiev'
}

const statSlice = createSlice({
    name: "stat",
    initialState,
    reducers: {
        updateStat(state,action) {
            state.passed = action.payload.passed
            state.blocked = action.payload.blocked
            state.total = action.payload.total
        },
        updateLoader(state,action) {
            state.loading = action.payload
        },
        updateAddCloackPopup(state,action) {
            state.addcloackpopup = action.payload
        },
        updateTopMenu(state,action) {
            state.topmenu = action.payload
        },
        updateUserData(state,action) {
            state.daysleft = action.payload.daysleft
            state.expires = action.payload.expires
            state.registered = action.payload.registered
            state.email = action.payload.email
            state.payments = action.payload.payments
            state.balance = action.payload.balance
            state.clicks = action.payload.clicks
            state.timezone = action.payload.timezone
            state.dataloaded = true
        },
        updateTimezone(state,action) {
            state.timezone = action.payload
        }
    }
})

export default statSlice.reducer
export const {
    updateStat,
    updateLoader,
    updateAddCloackPopup,
    updateTopMenu,
    updateUserData,
    updateTimezone
} = statSlice.actions