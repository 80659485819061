import screen3 from './../screenshots/step3.png'
import screen4 from './../screenshots/step4.png'
import screen5 from './../screenshots/step5.png'
import screen56 from './../screenshots/step56.jpg'
import screen7 from './../screenshots/step7.png'
import screen8 from './../screenshots/step8.jpg'
import screen9 from './../screenshots/step9.jpg'
import screen10 from './../screenshots/step10.jpg'
import screen11 from './../screenshots/step11.jpg'
import screen12 from './../screenshots/step12.jpg'
import screen13 from './../screenshots/step13.jpg'
import screen14 from './../screenshots/step14.jpg'
import screen15 from './../screenshots/step15.jpg'
import screen16 from './../screenshots/step16.jpg'
import screen17 from './../screenshots/step17.jpg'
import screen19 from './../screenshots/step19.png'
import screen20 from './../screenshots/step20.jpg'
import screen21 from './../screenshots/step21.jpg'
import screen22 from './../screenshots/step22.jpg'
import screen23 from './../screenshots/step23.jpg'
import screen24 from './../screenshots/step24.jpg'

export const HelpEn = () => {
    return (
        <>
            <h1>Help</h1>

            <h2>Getting a Password and Authorization Link</h2>
            <p>To use this application, you need to obtain a password and a link to the authorization page by sending a message on Telegram to <a href="https://t.me/sales_knk">@sales_knk</a> in any format.</p>

            <h2>Accessing the Authorization Page</h2>
            <p>Follow the provided link to the authorization page.</p>

            <h2>Entering Email</h2>
            <p>In the <strong>Email</strong> field, enter your email (see screenshot).</p>
            <p><img src={screen3} alt="Entering Email" className="img_wide_mobile" /></p>

            <h2>Entering Password</h2>
            <p>In the <strong>Password</strong> field, enter the password you received from us (see screenshot).</p>
            <p><img src={screen4} alt="" className="img_wide_mobile" /></p>

            <h2>Clicking the Auth Button</h2>
            <p>Click the <strong>Auth</strong> button (see screenshot).</p>
            <p><img src={screen5} alt="" className="img_wide_mobile" /></p>

            <h2>Time zone</h2>
            <p>Check and set the correct time zone for you in your account. To do this, go to the "Account" tab and select the appropriate time zone to avoid inaccuracies in reports.</p>
            <p><img src={screen56} alt="" className="img_wide_desktop" /></p>

            <h2>Opening the Cloak List Page</h2>
            <p>The page with the list of cloaks will open.</p>
            <p><img src={screen7} alt="" className="img_wide_mobile" /></p>

            <h2>Adding a New Cloak</h2>
            <p>Click the <strong>Add Cloak</strong> button (see screenshot).</p>
            <p><img src={screen7} alt="" className="img_wide_mobile" /></p>

            <h2>Entering Cloak Name</h2>
            <p>In the newly opened window, enter a random name for the cloak in the <strong>Cloak Name</strong> field (see screenshot).</p>
            <p><img src={screen8} alt="" className="img_wide_mobile" /></p>

            <h2>Adding White URL</h2>
            <p>In the <strong>White URL</strong> field, enter the address of the white website from which the redirect will originate, in the format <b>https://example1.com/</b> (see screenshot).</p>
            <p><img src={screen9} alt="" className="img_wide_mobile" /></p>

            <h2>Adding Black URL</h2>
            <p>In the <strong>Black URL</strong> field, enter the address of the grey website to which the redirect will lead, in the format <b>https://example2.com/</b> (see screenshot).</p>
            <p><img src={screen10} alt="" className="img_wide_mobile" /></p>

            <h2>Confirming Cloak Addition</h2>
            <p>Click the <strong>Add Cloak</strong> button (see screenshot).</p>
            <p><img src={screen11} alt="" className="img_wide_mobile" /></p>

            <h2>Configuring the Cloak</h2>
            <p>The cloak settings page will open. The fields <strong>Cloak Name</strong>, <strong>White URL</strong>, and <strong>Black URL</strong> should be filled with the data entered according to steps 8-10 (see screenshot).</p>
            <p><img src={screen12} alt="" className="img_wide_desktop" /></p>

            <h2>Specifying Target Countries for Ads</h2>
            <p>On the settings page, enter all target country codes, separated by commas, in the <strong>Countries</strong> field for the white website's ad campaigns (see screenshot). Users from all other countries will be redirected to the white page.</p>
            <p><img src={screen13} alt="" className="img_wide_desktop" /></p>

            <table className="help_table">
                <thead>
                <tr>
                    <th>Country</th>
                    <th>Code</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>Afghanistan</td>
                    <td>AF</td>
                </tr>
                <tr>
                    <td>Albania</td>
                    <td>AL</td>
                </tr>
                <tr>
                    <td>Algeria</td>
                    <td>DZ</td>
                </tr>
                <tr>
                    <td>Andorra</td>
                    <td>AD</td>
                </tr>
                <tr>
                    <td>Angola</td>
                    <td>AO</td>
                </tr>
                <tr>
                    <td>Antigua and Barbuda</td>
                    <td>AG</td>
                </tr>
                <tr>
                    <td>Argentina</td>
                    <td>AR</td>
                </tr>
                <tr>
                    <td>Armenia</td>
                    <td>AM</td>
                </tr>
                <tr>
                    <td>Australia</td>
                    <td>AU</td>
                </tr>
                <tr>
                    <td>Austria</td>
                    <td>AT</td>
                </tr>
                <tr>
                    <td>Azerbaijan</td>
                    <td>AZ</td>
                </tr>
                <tr>
                    <td>Bahamas</td>
                    <td>BS</td>
                </tr>
                <tr>
                    <td>Bahrain</td>
                    <td>BH</td>
                </tr>
                <tr>
                    <td>Bangladesh</td>
                    <td>BD</td>
                </tr>
                <tr>
                    <td>Barbados</td>
                    <td>BB</td>
                </tr>
                <tr>
                    <td>Belarus</td>
                    <td>BY</td>
                </tr>
                <tr>
                    <td>Belgium</td>
                    <td>BE</td>
                </tr>
                <tr>
                    <td>Belize</td>
                    <td>BZ</td>
                </tr>
                <tr>
                    <td>Benin</td>
                    <td>BJ</td>
                </tr>
                <tr>
                    <td>Bhutan</td>
                    <td>BT</td>
                </tr>
                <tr>
                    <td>Bolivia</td>
                    <td>BO</td>
                </tr>
                <tr>
                    <td>Bosnia and Herzegovina</td>
                    <td>BA</td>
                </tr>
                <tr>
                    <td>Botswana</td>
                    <td>BW</td>
                </tr>
                <tr>
                    <td>Brazil</td>
                    <td>BR</td>
                </tr>
                <tr>
                    <td>Brunei</td>
                    <td>BN</td>
                </tr>
                <tr>
                    <td>Bulgaria</td>
                    <td>BG</td>
                </tr>
                <tr>
                    <td>Burkina Faso</td>
                    <td>BF</td>
                </tr>
                <tr>
                    <td>Burundi</td>
                    <td>BI</td>
                </tr>
                <tr>
                    <td>Cabo Verde</td>
                    <td>CV</td>
                </tr>
                <tr>
                    <td>Cambodia</td>
                    <td>KH</td>
                </tr>
                <tr>
                    <td>Cameroon</td>
                    <td>CM</td>
                </tr>
                <tr>
                    <td>Canada</td>
                    <td>CA</td>
                </tr>
                <tr>
                    <td>Central African Republic</td>
                    <td>CF</td>
                </tr>
                <tr>
                    <td>Chad</td>
                    <td>TD</td>
                </tr>
                <tr>
                    <td>Chile</td>
                    <td>CL</td>
                </tr>
                <tr>
                    <td>China</td>
                    <td>CN</td>
                </tr>
                <tr>
                    <td>Colombia</td>
                    <td>CO</td>
                </tr>
                <tr>
                    <td>Comoros</td>
                    <td>KM</td>
                </tr>
                <tr>
                    <td>Congo (Congo-Brazzaville)</td>
                    <td>CG</td>
                </tr>
                <tr>
                    <td>Costa Rica</td>
                    <td>CR</td>
                </tr>
                <tr>
                    <td>Croatia</td>
                    <td>HR</td>
                </tr>
                <tr>
                    <td>Cuba</td>
                    <td>CU</td>
                </tr>
                <tr>
                    <td>Cyprus</td>
                    <td>CY</td>
                </tr>
                <tr>
                    <td>Czechia (Czech Republic)</td>
                    <td>CZ</td>
                </tr>
                <tr>
                    <td>Denmark</td>
                    <td>DK</td>
                </tr>
                <tr>
                    <td>Djibouti</td>
                    <td>DJ</td>
                </tr>
                <tr>
                    <td>Dominica</td>
                    <td>DM</td>
                </tr>
                <tr>
                    <td>Dominican Republic</td>
                    <td>DO</td>
                </tr>
                <tr>
                    <td>Ecuador</td>
                    <td>EC</td>
                </tr>
                <tr>
                    <td>Egypt</td>
                    <td>EG</td>
                </tr>
                <tr>
                    <td>El Salvador</td>
                    <td>SV</td>
                </tr>
                <tr>
                    <td>Equatorial Guinea</td>
                    <td>GQ</td>
                </tr>
                <tr>
                    <td>Eritrea</td>
                    <td>ER</td>
                </tr>
                <tr>
                    <td>Estonia</td>
                    <td>EE</td>
                </tr>
                <tr>
                    <td>Eswatini</td>
                    <td>SZ</td>
                </tr>
                <tr>
                    <td>Ethiopia</td>
                    <td>ET</td>
                </tr>
                <tr>
                    <td>Fiji</td>
                    <td>FJ</td>
                </tr>
                <tr>
                    <td>Finland</td>
                    <td>FI</td>
                </tr>
                <tr>
                    <td>France</td>
                    <td>FR</td>
                </tr>
                <tr>
                    <td>Gabon</td>
                    <td>GA</td>
                </tr>
                <tr>
                    <td>Gambia</td>
                    <td>GM</td>
                </tr>
                <tr>
                    <td>Georgia</td>
                    <td>GE</td>
                </tr>
                <tr>
                    <td>Germany</td>
                    <td>DE</td>
                </tr>
                <tr>
                    <td>Ghana</td>
                    <td>GH</td>
                </tr>
                <tr>
                    <td>Greece</td>
                    <td>GR</td>
                </tr>
                <tr>
                    <td>Grenada</td>
                    <td>GD</td>
                </tr>
                <tr>
                    <td>Guatemala</td>
                    <td>GT</td>
                </tr>
                <tr>
                    <td>Guinea</td>
                    <td>GN</td>
                </tr>
                <tr>
                    <td>Guinea-Bissau</td>
                    <td>GW</td>
                </tr>
                <tr>
                    <td>Guyana</td>
                    <td>GY</td>
                </tr>
                <tr>
                    <td>Haiti</td>
                    <td>HT</td>
                </tr>
                <tr>
                    <td>Honduras</td>
                    <td>HN</td>
                </tr>
                <tr>
                    <td>Hungary</td>
                    <td>HU</td>
                </tr>
                <tr>
                    <td>Iceland</td>
                    <td>IS</td>
                </tr>
                <tr>
                    <td>India</td>
                    <td>IN</td>
                </tr>
                <tr>
                    <td>Indonesia</td>
                    <td>ID</td>
                </tr>
                <tr>
                    <td>Iran</td>
                    <td>IR</td>
                </tr>
                <tr>
                    <td>Iraq</td>
                    <td>IQ</td>
                </tr>
                <tr>
                    <td>Ireland</td>
                    <td>IE</td>
                </tr>
                <tr>
                    <td>Israel</td>
                    <td>IL</td>
                </tr>
                <tr>
                    <td>Italy</td>
                    <td>IT</td>
                </tr>
                <tr>
                    <td>Jamaica</td>
                    <td>JM</td>
                </tr>
                <tr>
                    <td>Japan</td>
                    <td>JP</td>
                </tr>
                <tr>
                    <td>Jordan</td>
                    <td>JO</td>
                </tr>
                <tr>
                    <td>Kazakhstan</td>
                    <td>KZ</td>
                </tr>
                <tr>
                    <td>Kenya</td>
                    <td>KE</td>
                </tr>
                <tr>
                    <td>Kiribati</td>
                    <td>KI</td>
                </tr>
                <tr>
                    <td>Kuwait</td>
                    <td>KW</td>
                </tr>
                <tr>
                    <td>Kyrgyzstan</td>
                    <td>KG</td>
                </tr>
                <tr>
                    <td>Laos</td>
                    <td>LA</td>
                </tr>
                <tr>
                    <td>Latvia</td>
                    <td>LV</td>
                </tr>
                <tr>
                    <td>Lebanon</td>
                    <td>LB</td>
                </tr>
                <tr>
                    <td>Lesotho</td>
                    <td>LS</td>
                </tr>
                <tr>
                    <td>Liberia</td>
                    <td>LR</td>
                </tr>
                <tr>
                    <td>Libya</td>
                    <td>LY</td>
                </tr>
                <tr>
                    <td>Liechtenstein</td>
                    <td>LI</td>
                </tr>
                <tr>
                    <td>Lithuania</td>
                    <td>LT</td>
                </tr>
                <tr>
                    <td>Luxembourg</td>
                    <td>LU</td>
                </tr>
                <tr>
                    <td>Madagascar</td>
                    <td>MG</td>
                </tr>
                <tr>
                    <td>Malawi</td>
                    <td>MW</td>
                </tr>
                <tr>
                    <td>Malaysia</td>
                    <td>MY</td>
                </tr>
                <tr>
                    <td>Maldives</td>
                    <td>MV</td>
                </tr>
                <tr>
                    <td>Mali</td>
                    <td>ML</td>
                </tr>
                <tr>
                    <td>Malta</td>
                    <td>MT</td>
                </tr>
                <tr>
                    <td>Marshall Islands</td>
                    <td>MH</td>
                </tr>
                <tr>
                    <td>Mauritania</td>
                    <td>MR</td>
                </tr>
                <tr>
                    <td>Mauritius</td>
                    <td>MU</td>
                </tr>
                <tr>
                    <td>Mexico</td>
                    <td>MX</td>
                </tr>
                <tr>
                    <td>Micronesia</td>
                    <td>FM</td>
                </tr>
                <tr>
                    <td>Moldova</td>
                    <td>MD</td>
                </tr>
                <tr>
                    <td>Monaco</td>
                    <td>MC</td>
                </tr>
                <tr>
                    <td>Mongolia</td>
                    <td>MN</td>
                </tr>
                <tr>
                    <td>Montenegro</td>
                    <td>ME</td>
                </tr>
                <tr>
                    <td>Morocco</td>
                    <td>MA</td>
                </tr>
                <tr>
                    <td>Mozambique</td>
                    <td>MZ</td>
                </tr>
                <tr>
                    <td>Myanmar (Burma)</td>
                    <td>MM</td>
                </tr>
                <tr>
                    <td>Namibia</td>
                    <td>NA</td>
                </tr>
                <tr>
                    <td>Nauru</td>
                    <td>NR</td>
                </tr>
                <tr>
                    <td>Nepal</td>
                    <td>NP</td>
                </tr>
                <tr>
                    <td>Netherlands</td>
                    <td>NL</td>
                </tr>
                <tr>
                    <td>New Zealand</td>
                    <td>NZ</td>
                </tr>
                <tr>
                    <td>Nicaragua</td>
                    <td>NI</td>
                </tr>
                <tr>
                    <td>Niger</td>
                    <td>NE</td>
                </tr>
                <tr>
                    <td>Nigeria</td>
                    <td>NG</td>
                </tr>
                <tr>
                    <td>North Macedonia</td>
                    <td>MK</td>
                </tr>
                <tr>
                    <td>Norway</td>
                    <td>NO</td>
                </tr>
                <tr>
                    <td>Oman</td>
                    <td>OM</td>
                </tr>
                <tr>
                    <td>Pakistan</td>
                    <td>PK</td>
                </tr>
                <tr>
                    <td>Palau</td>
                    <td>PW</td>
                </tr>
                <tr>
                    <td>Panama</td>
                    <td>PA</td>
                </tr>
                <tr>
                    <td>Papua New Guinea</td>
                    <td>PG</td>
                </tr>
                <tr>
                    <td>Paraguay</td>
                    <td>PY</td>
                </tr>
                <tr>
                    <td>Peru</td>
                    <td>PE</td>
                </tr>
                <tr>
                    <td>Philippines</td>
                    <td>PH</td>
                </tr>
                <tr>
                    <td>Poland</td>
                    <td>PL</td>
                </tr>
                <tr>
                    <td>Portugal</td>
                    <td>PT</td>
                </tr>
                <tr>
                    <td>Qatar</td>
                    <td>QA</td>
                </tr>
                <tr>
                    <td>Romania</td>
                    <td>RO</td>
                </tr>
                <tr>
                    <td>Russia</td>
                    <td>RU</td>
                </tr>
                <tr>
                    <td>Rwanda</td>
                    <td>RW</td>
                </tr>
                <tr>
                    <td>Saint Kitts and Nevis</td>
                    <td>KN</td>
                </tr>
                <tr>
                    <td>Saint Lucia</td>
                    <td>LC</td>
                </tr>
                <tr>
                    <td>Saint Vincent and the Grenadines</td>
                    <td>VC</td>
                </tr>
                <tr>
                    <td>Samoa</td>
                    <td>WS</td>
                </tr>
                <tr>
                    <td>San Marino</td>
                    <td>SM</td>
                </tr>
                <tr>
                    <td>Sao Tome and Principe</td>
                    <td>ST</td>
                </tr>
                <tr>
                    <td>Saudi Arabia</td>
                    <td>SA</td>
                </tr>
                <tr>
                    <td>Senegal</td>
                    <td>SN</td>
                </tr>
                <tr>
                    <td>Serbia</td>
                    <td>RS</td>
                </tr>
                <tr>
                    <td>Seychelles</td>
                    <td>SC</td>
                </tr>
                <tr>
                    <td>Sierra Leone</td>
                    <td>SL</td>
                </tr>
                <tr>
                    <td>Singapore</td>
                    <td>SG</td>
                </tr>
                <tr>
                    <td>Slovakia</td>
                    <td>SK</td>
                </tr>
                <tr>
                    <td>Slovenia</td>
                    <td>SI</td>
                </tr>
                <tr>
                    <td>Solomon Islands</td>
                    <td>SB</td>
                </tr>
                <tr>
                    <td>Somalia</td>
                    <td>SO</td>
                </tr>
                <tr>
                    <td>South Africa</td>
                    <td>ZA</td>
                </tr>
                <tr>
                    <td>South Sudan</td>
                    <td>SS</td>
                </tr>
                <tr>
                    <td>Spain</td>
                    <td>ES</td>
                </tr>
                <tr>
                    <td>Sri Lanka</td>
                    <td>LK</td>
                </tr>
                <tr>
                    <td>Sudan</td>
                    <td>SD</td>
                </tr>
                <tr>
                    <td>Suriname</td>
                    <td>SR</td>
                </tr>
                <tr>
                    <td>Sweden</td>
                    <td>SE</td>
                </tr>
                <tr>
                    <td>Switzerland</td>
                    <td>CH</td>
                </tr>
                <tr>
                    <td>Syria</td>
                    <td>SY</td>
                </tr>
                <tr>
                    <td>Taiwan</td>
                    <td>TW</td>
                </tr>
                <tr>
                    <td>Tajikistan</td>
                    <td>TJ</td>
                </tr>
                <tr>
                    <td>Tanzania</td>
                    <td>TZ</td>
                </tr>
                <tr>
                    <td>Thailand</td>
                    <td>TH</td>
                </tr>
                <tr>
                    <td>Timor-Leste</td>
                    <td>TL</td>
                </tr>
                <tr>
                    <td>Togo</td>
                    <td>TG</td>
                </tr>
                <tr>
                    <td>Tonga</td>
                    <td>TO</td>
                </tr>
                <tr>
                    <td>Trinidad and Tobago</td>
                    <td>TT</td>
                </tr>
                <tr>
                    <td>Tunisia</td>
                    <td>TN</td>
                </tr>
                <tr>
                    <td>Turkey</td>
                    <td>TR</td>
                </tr>
                <tr>
                    <td>Turkmenistan</td>
                    <td>TM</td>
                </tr>
                <tr>
                    <td>Tuvalu</td>
                    <td>TV</td>
                </tr>
                <tr>
                    <td>Uganda</td>
                    <td>UG</td>
                </tr>
                <tr>
                    <td>Ukraine</td>
                    <td>UA</td>
                </tr>
                <tr>
                    <td>United Arab Emirates</td>
                    <td>AE</td>
                </tr>
                <tr>
                    <td>United Kingdom</td>
                    <td>GB</td>
                </tr>
                <tr>
                    <td>United States</td>
                    <td>US</td>
                </tr>
                <tr>
                    <td>Uruguay</td>
                    <td>UY</td>
                </tr>
                <tr>
                    <td>Uzbekistan</td>
                    <td>UZ</td>
                </tr>
                <tr>
                    <td>Vanuatu</td>
                    <td>VU</td>
                </tr>
                <tr>
                    <td>Vatican City</td>
                    <td>VA</td>
                </tr>
                <tr>
                    <td>Venezuela</td>
                    <td>VE</td>
                </tr>
                <tr>
                    <td>Vietnam</td>
                    <td>VN</td>
                </tr>
                <tr>
                    <td>Yemen</td>
                    <td>YE</td>
                </tr>
                <tr>
                    <td>Zambia</td>
                    <td>ZM</td>
                </tr>
                <tr>
                    <td>Zimbabwe</td>
                    <td>ZW</td>
                </tr>
                </tbody>
            </table>

            <h2>Specifying Countries for Blocking</h2>
            <p>In the <strong>Countries Stop</strong> field, enter all country codes separated by commas for which you want to block redirects or content substitution. If blocking is not needed, leave the field empty (see screenshot). The list of blocked countries should be used if the Allowed Countries field is empty and you want to allow users from all countries except those specified.</p>
            <p><img src={screen14} alt="" className="img_wide_desktop" /></p>

            <h2>Setting Up Delay</h2>
            <p>In the <strong>Cloak Delay</strong> field, enter the delay time for the cloak activation in milliseconds. By default, the value is set to “0”. You can manually change the delay time, but it is recommended to leave it at "0". Keep in mind that some checks require additional time. For example, detecting emulators requires gathering additional device attributes, which increases data retrieval time. The delay can range from 300 to 1500 ms (see screenshot).</p>
            <p><img src={screen15} alt="" className="img_wide_desktop" /></p>

            <h2>Adding Device Operating Systems</h2>
            <p>Select the device operating systems (checkboxes) that were specified when setting up ads for the <strong>White URL</strong>. Available options: Android, iOS, Mac, Windows, Linux, Chrome OS (see screenshot). Only users accessing the site from these specified devices (where checkboxes are selected) will be redirected to the <strong>Black URL</strong>.</p>
            <p><img src={screen16} alt="" className="img_wide_desktop" /></p>

            <h2>Specifying User Verification Criteria</h2>
            <p>Select the criteria (checkboxes) based on which users of the <strong>White URL</strong> will be verified (see screenshot). Users who fail the specified checks will remain on the <strong>White URL</strong>. For example, if you check VPN and a user accesses the <strong>White URL</strong> via a Google link with an enabled VPN in the browser or on a mobile device, the cloak will not allow them to the <strong>Black URL</strong>. Below is a description of each verification criterion:</p>
            <p><img src={screen17} alt="" className="img_wide_desktop" /></p>

            <h3>VPN</h3>
            <p>Determines whether the user's time zone matches the time zone of their originating IP address. It also provides information on whether the IP address is public and associated with a known VPN provider. Additionally, it checks if the client’s operating system matches the one indicated in network traffic.</p>

            <h3>Incognito</h3>
            <p>Detects whether the visitor is using incognito or private browsing mode to access the website.</p>

            <h3>Browser Bots</h3>
            <p>Identifies data about both useful and malicious bots, allowing them to be blocked or filtered out of normal traffic and preventing automated abuse.</p>

            <h3>Browser Tampering</h3>
            <p>Some simple techniques can bypass less sophisticated browser fingerprinting algorithms, such as modifying the User Agent or manipulating collected browser signals, including the use of anti-detect browsers. Detecting unauthorized access attempts helps identify such behavior by comparing the browser’s signature with a statistical model and checking whether a browser with detection bypass features is being used.</p>

            <h3>Virtual Machines</h3>
            <p>Determines whether the browser is running in a virtualization environment, such as VirtualBox, by analyzing the browser’s configuration.</p>

            <h3>Privacy Settings</h3>
            <p>Privacy-focused browsers like Firefox, Brave, and others actively counter fingerprinting and provide settings that can randomize and obscure output data. This check determines whether such settings are enabled and generates the corresponding signal.</p>

            <h3>Developer Tools</h3>
            <p>Checks whether developer tools are manually opened in Chrome or Firefox browsers.</p>

            <h2>Selecting the Black Page Display Type</h2>
            <p>Select <strong>"With Redirect"</strong> if a redirect is needed or <strong>"In Frame"</strong> if content substitution is required (see screenshot). When using the "In Frame" black page display type, the black page must be on the same domain as the white page. This display type shows the black page without changing the URL in the address bar. If the "With Redirect" type is used, the user will be redirected to the black page via a standard redirect, meaning the black page can be on any domain.</p>
            <p><img src={screen19} alt="" className="img_wide_desktop" /></p>

            <h2>Inserting Code on the Website</h2>
            <p>Copy the code shown at the bottom of the page with a black background and insert it into the website (from which the redirect will originate) after the opening <code>&lt;head&gt;</code> tag (see screenshot).</p>
            <p><img src={screen20} alt="" className="img_wide_desktop" /></p>

            <h2>GET Parameters</h2>
            <p>Additionally, there is an option to configure GET parameters. For example, if you are running ads in different countries, and in one country the click identifier is <strong>gclid</strong>, while in another it is <strong>wbraid</strong>, you should select "Consider one of the GET parameters" (see screenshot). In this case, the cloak will allow users from these two countries with different click identifiers.</p>
            <p><img src={screen21} alt="" className="img_wide_desktop" /></p>

            <h2>Split</h2>
            <p>There is also an option to configure split targeting for different countries using different black pages (see screenshot). These countries must be listed as allowed.</p>
            <p><img src={screen22} alt="" className="img_wide_desktop" /></p>

            <h2>Completing the Setup</h2>
            <p>Enable the cloak. The cloak is now set up. You can view statistics (see screenshot). Our user-friendly dashboard allows you to see detailed information on each user, including reasons for blocking. You can also stay updated with application changes via our Telegram channel.</p>
            <p><img src={screen23} alt="" className="img_wide_desktop" /></p>

            <h2>Click Information</h2>
            <p>A feature has been added to view the number of passed, blocked, and total clicks for each campaign over a selected period. To do this, go to the Clicks tab, select a date range, and click the <b>Show</b> button. You can also download a click report in CSV format by clicking the "Download CSV" button and saving it to your computer (see screenshot).</p>
            <p><img src={screen24} alt="" className="img_wide_desktop" /></p>

        </>
    )
}