import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { formatLocalizedDate, GridTable } from '../functions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight, faMobileButton, faRefresh } from '@fortawesome/free-solid-svg-icons';
import { faWindows, faApple, faAndroid, faLinux, faChrome } from "@fortawesome/free-brands-svg-icons";
import Flag from 'react-world-flags';
import { updateLoader, updateStat } from '../redux/statSlice';

export const StatOld = () => {

    const dispatch = useDispatch();

    const userID = localStorage.getItem('user.id')

    const statDataRedux = useSelector((state) => state.stat);

    const [onPage, setOnPage] = useState(localStorage.getItem('on.page') || 50);
    const [page, setPage] = useState(parseInt(localStorage.getItem('page')) || 1);
    const [search, setSearch] = useState('');
    const [statData, setStatData] = useState([]);
    const [statMeta, setStatMeta] = useState({});
    const [status, setStatus] = useState(localStorage.getItem('status') || 'all');
    const [domain, setDomain] = useState('all');
    const [domains, setDomains] = useState([]);
    const [dateFrom, setDateFrom] = useState('');
    const [dateTo, setDateTo] = useState('');

    useEffect(() => {
        setStatData([]);
        dispatch(updateLoader(true));

        const fetchData = async () => {
            try {
                const searchParams = new URLSearchParams({
                    action: 'getvisits',
                    server: localStorage.getItem('server'),
                    id: userID,
                    onpage: onPage,
                    page: page,
                    search: search,
                    status: status,
                    domain: domain,
                    datefrom: dateFrom,
                    dateto: dateTo
                });

                const response = await fetch(`https://spaceshipbridge.com/ud/api.php?${searchParams.toString()}`);
                const json = await response.json();

                // console.log(json)

                if (json.data) {

                    setStatData(json.data)

                    const passed = json.data.filter(item => item.status === 'passed').length
                    const blocked = json.data.filter(item => item.status === 'blocked').length

                    dispatch(updateStat({
                        passed: passed,
                        blocked: blocked,
                        total: passed + blocked
                    }))
                }

                if (json.meta) {
                    setStatMeta(json.meta)
                    if (json.meta.total === 0 && !localStorage.getItem('was.auth')) {
                        window.location.href = '/cloaks'
                        localStorage.setItem('was.auth', 'yes')
                    }
                }

            } catch (error) {
                console.error(error);
            } finally {
                dispatch(updateLoader(false));
            }
        };

        fetchData();

    }, [onPage, page, search, status, domain, userID, dateFrom, dateTo, dispatch]);

    useEffect(() => {
        setStatData([]);
        dispatch(updateLoader(true));

        const fetchDomains = async () => {
            try {
                const searchParams = new URLSearchParams({
                    action: 'getdomains',
                    uuid: userID
                });

                const response = await fetch(`https://spaceshipbridge.com/ud/api.php?${searchParams.toString()}`);
                const json = await response.json();

                if (json.domains) {
                    setDomains(json.domains);
                }

            } catch (error) {
                console.error(error);
            } finally {
                // dispatch(updateLoader(false));
            }
        };

        fetchDomains();

    }, [userID, dispatch])

    // Обработчики событий и остальной код

    const pageChange = event => {
        const value = event.target.value;
        setPage(value);
        // localStorage.setItem('page', value);
    };
    const onPageChange = event => {
        const value = event.target.value;
        setOnPage(value);
        // localStorage.setItem('on.page', value);
    };
    const statusChange = event => {
        const value = event.target.value;
        setStatus(value);
        // localStorage.setItem('status', value);
    };
    const changeSearch = event => {
        const value = event.target.value;
        setSearch(value);
    };
    const pageMinus = () => setPage(prevPage => Math.max(prevPage - 1, 1));
    const pagePlus = () => setPage(prevPage => prevPage + 1);

    const gclidClick = event => {
        const textToCopy = event.target.innerText;
        navigator.clipboard.writeText(textToCopy)
            .then(() => {
                alert(`Copied to clipboard: ${textToCopy}`);
            })
            .catch(err => {
                console.error('Failed to copy text:', err);
            });
    };

    const changeDomain = event => {
        const value = event.target.value;
        setDomain(value);
    };

    const dateChange = setter => event => {
        const value = event.target.value;
        setter(value);
    };

    const Navigation = () => {
        return (
            <div className="stat_header">
                <div data-help="Visits on page">
                    <select
                        className="form-control"
                        name="onpage"
                        onChange={onPageChange}
                        value={onPage}
                    >
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="200">200</option>
                        <option value="500">500</option>
                        <option value="1000">1000</option>
                        <option value="2000">2000</option>
                        <option value="all">All</option>
                    </select>
                </div>
                <div className="stat_header_page" data-help="Pages: current / total">
                    <button className="btn btn-default" onClick={pageMinus}><FontAwesomeIcon icon={faArrowLeft} /></button>
                    <input
                        className="form-control form-control-centered"
                        value={page}
                        onChange={pageChange}
                    />
                    <div className="form-control form-control-centered total_pages">{statMeta.pages}</div>
                    <button className="btn btn-default" onClick={pagePlus}><FontAwesomeIcon icon={faArrowRight} /></button>
                </div>
                <div data-help="Search in visitor ID and gclid">
                    <input
                        type="search"
                        className="form-control"
                        placeholder="Search"
                        value={search}
                        onChange={changeSearch}
                    />
                </div>
                <div className="stat_header_dates" data-help="Date range from and to">
                    <input
                        type="date"
                        className="form-control"
                        name="datefrom"
                        value={dateFrom}
                        onChange={dateChange(setDateFrom)}
                    />
                    <input
                        type="date"
                        className="form-control"
                        name="dateto"
                        value={dateTo}
                        onChange={dateChange(setDateTo)}
                    />
                </div>
                <div data-help="Sorting by passed and blocked visits">
                    <select
                        className="form-control"
                        name="status"
                        value={status}
                        onChange={statusChange}
                    >
                        <option value="all">All statuses</option>
                        <option value="passed">Passed</option>
                        <option value="blocked">Blocked</option>
                    </select>
                </div>
                <div data-help="Filtering by domain">
                    <select
                        className="form-control"
                        name="domain"
                        value={domain}
                        onChange={changeDomain}
                    >
                        <option value="all">All domains</option>
                        {domains.map((e, i) => <option value={e} key={`domains${i}`}>{e}</option>)}
                    </select>
                </div>
            </div>
        );
    };

    return (
        <>
            <Navigation />
            {statDataRedux.loading
            ?
            <GridTable rows={10} columns={5} />
            :
            <table className="table">
                <thead>
                    <tr>
                        <th>Domain / Cloak name</th>
                        <th>Visitor ID / gclid</th>
                        <th>Status / Referrer</th>
                        <th>White / Black</th>
                        <th>Date / IP Country OS</th>
                    </tr>
                </thead>
                <tbody>
                {statData.map((e, i) =>
                        <React.Fragment key={`tabletr${i}`}>
                            <tr className={e.status}>
                                <td><strong>{e.domain_name}</strong></td>
                                <td className="wrap"><span className="show900">Visitor ID: </span>{e.visitor_id}</td>
                                <td className="wrap">{e.status}: {e.reason}</td>
                                <td><span className="show900">White: </span><span className="table_nowrap" onClick={gclidClick}>{e.white}</span></td>
                                <td>{formatLocalizedDate(e.timestamp,statDataRedux.timezone)}</td>
                            </tr>
                            <tr className={e.status}>
                                <td>
                                    {(e.cloack_uuid && e.cloack_name) ? <><span className="show900">Cloak: </span><a href={`/cloak/${e.cloack_uuid}`}>{e.cloack_name}</a></> : ''}
                                </td>
                                <td><span className="show900">gclid: </span><span className="table_nowrap" onClick={gclidClick}>{e.gclid}</span></td>
                                <td className="wrap"><span className="table_nowrap" onClick={gclidClick}>{e.referrer}</span></td>
                                <td><span className="show900">Black: </span><span className="table_nowrap" onClick={gclidClick}>{e.black}</span></td>
                                <td>
                                    <span className="oscountry">
                                        <a href={`https://ipinfo.io/${e.ip}`} target="_blank" rel="nofollow noopener noreferrer">{e.ip}</a>
                                        {e.country !== '' && <Flag code={e.country} alt={e.country} style={{ width: '16px', height: '16px' }} />}
                                        {e.os === 'windows' && <FontAwesomeIcon icon={faWindows} className="windows" title="Windows" />}
                                        {(e.os === 'mac' || e.os === 'mac os x' || e.os === 'macos') && <FontAwesomeIcon icon={faApple} className="macos" title="OS X" />}
                                        {e.os === 'android' && <FontAwesomeIcon icon={faAndroid} className="android" title="Android" />}
                                        {e.os === 'ios' && <FontAwesomeIcon icon={faMobileButton} title="iOS" />}
                                        {(e.os === 'linux' || e.os === 'openbsd' || e.os === 'solaris' || e.os === 'ubuntu') && <FontAwesomeIcon icon={faLinux} title="Linux" />}
                                        {(e.os === 'chromeos' || e.os === 'chrome os' || e.os === 'chromecast') && <FontAwesomeIcon icon={faChrome} className="chromeos" title="Chrome OS" />}
                                    </span>
                                </td>
                            </tr>
                        </React.Fragment>
                    )}
                </tbody>
            </table>
            }
            <Navigation />
            <button className="btn btn-primary refresh_stat" onClick={() => window.location.reload()}><FontAwesomeIcon icon={faRefresh} /></button>
        </>
    );
};
