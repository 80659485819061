import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { StatOld } from './components/StatOld';
import { Account } from './components/Account';
import { Cloack } from './components/Cloack';
import { Cloacks } from './components/Cloacks';
import { LoginPage } from './components/LoginPage';
import { Help } from './components/Help';
import { Clicks } from './components/Clicks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StatNew } from './components/StatNew';
import { BlackList } from './components/BlackList';
import {
    faArrowRightFromBracket,
    faShield,
    faUser,
    faCircleQuestion,
    faBars,
    faClose,
    faChartLine,
    faArrowPointer,
    faBan,
} from '@fortawesome/free-solid-svg-icons';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { updateUserData, updateTopMenu } from './redux/statSlice';
import { daysUntil } from './functions';

import './App.css';
import { Changelog } from './components/Changelog';

import { t } from 'i18next';
import { Trans } from 'react-i18next';

import { ReactComponent as Logo } from './logotip.svg'

!localStorage.getItem('locale') && localStorage.setItem('locale', 'ru');

const App = () => {
    const dispatch = useDispatch();

    if (!localStorage.getItem('server')) localStorage.setItem('server','spaceshipbridge.com')

    const logOut = () => {
        localStorage.removeItem('user.id');
        localStorage.removeItem('was.auth');
        localStorage.removeItem('server');
        localStorage.removeItem('timezone');
        window.location.href = '/';
    };

    const statData = useSelector((state) => state.stat);

    const showHideMenu = () => {
        const newValue = statData.topmenu ? false : true;
        dispatch(updateTopMenu(newValue));
    };

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await fetch(
                    `https://spaceshipbridge.com/ud/api.php?action=getuserdata&id=${localStorage.getItem('user.id')}&server=${localStorage.getItem('server')}`
                );
                const json = await response.json();
                if (json.expires) {
                    dispatch(
                        updateUserData({
                            daysleft: daysUntil(json.expires),
                            expires: json.expires,
                            registered: json.registered,
                            email: json.email,
                            payments: json.payments,
                            balance: json.balance,
                            clicks: json.clicks,
                            timezone: json.timezone
                        })
                    );
                }
            } catch (error) {
                console.error(error);
            }
        };
        fetchUserData();
    }, [dispatch]);

    const HeaderMenu = () => {
        return (
            <ul className="header_menu">
                <li>
                    <a className="header_menu_link" href="/">
                        <FontAwesomeIcon icon={faChartLine} />
                        {t('cloak.stat.stat')}
                    </a>
                </li>
                <li>
                    <a className="header_menu_link" href="/clicks/">
                        <FontAwesomeIcon icon={faArrowPointer} />
                        {t('cloak.header.clicks')}
                    </a>
                </li>
                <li>
                    <a className="header_menu_link" href="/cloaks">
                        <FontAwesomeIcon icon={faShield} />
                        {t('cloak.header.cloaks')}
                    </a>
                </li>
                <li>
                    <a className="header_menu_link" href="/blacklist">
                        <FontAwesomeIcon icon={faBan} />
                        Blacklist
                    </a>
                </li>
                <li>
                    <a className="header_menu_link" href="/help">
                        <FontAwesomeIcon icon={faCircleQuestion} />
                        {t('cloak.header.help')}
                    </a>
                </li>
                <li>
                    <a className="header_menu_link" href="/account">
                        <FontAwesomeIcon icon={faUser} />
                        {t('cloak.header.account')}
                    </a>
                </li>
                <li>
                    <button className="header_menu_link header_menu_link_logout" type="button" onClick={logOut}>
                        <FontAwesomeIcon icon={faArrowRightFromBracket} />
                        <span className="logout">{t('cloak.header.logout')}</span>
                    </button>
                </li>
            </ul>
        );
    };

    const changeLang = (e) => {
        const currentLang = e.target.dataset.value;
        localStorage.setItem('locale', currentLang);
        window.location.reload();
    };

    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const today = formatDate(new Date());
    const [topStripe, setTopStripe] = useState(localStorage.getItem('top.stripe') === today ? false : true);

    const CloseTopStripe = () => {
        setTopStripe(false);
        localStorage.setItem('top.stripe', today);
    };

    return (
        <div className="App">
            <Router>
                {localStorage.getItem('user.id') ? (
                    <>
                        {(statData.daysleft <= 7 && statData.daysleft > 3 && topStripe && statData.dataloaded) && (
                            <div className="top_stripe warning">
                                <span>
                                    <Trans
                                        i18nKey="cloak.topstripe.3days"
                                        values={{ count: statData.daysleft }}
                                        components={{
                                            a: (
                                                <a
                                                    href="https://t.me/yuliia_m_knk"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    @yuliia_m_knk
                                                </a>
                                            ),
                                        }}
                                    />
                                </span>
                                <button className="top_stripe_close" onClick={CloseTopStripe}></button>
                            </div>
                        )}
                        {(statData.daysleft <= 3 && statData.daysleft > 0 && topStripe && statData.dataloaded) && (
                            <div className="top_stripe danger">
                                <span>
                                    <Trans
                                        i18nKey="cloak.topstripe.3days"
                                        values={{ count: statData.daysleft }}
                                        components={{
                                            a: (
                                                <a
                                                    href="https://t.me/yuliia_m_knk"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    @yuliia_m_knk
                                                </a>
                                            ),
                                        }}
                                    />
                                </span>
                                <button className="top_stripe_close" onClick={CloseTopStripe}></button>
                            </div>
                        )}
                        {(statData.daysleft <= 0 && topStripe && statData.dataloaded) && (
                            <div className="top_stripe danger">
                                <span>
                                    <Trans
                                        i18nKey="cloak.topstripe.isover"
                                        values={{ count: statData.daysleft }}
                                        components={{
                                            a: (
                                                <a
                                                    href="https://t.me/yuliia_m_knk"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    @yuliia_m_knk
                                                </a>
                                            ),
                                        }}
                                    />
                                </span>
                                <button className="top_stripe_close" onClick={CloseTopStripe}></button>
                            </div>
                        )}
                        <header className="header">
                            <a className="logo" href="/">
                                <Logo />
                            </a>
                            {statData.passed > 0 || statData.blocked > 0 ? (
                                <div className="stat_columns">
                                    <div>
                                        <span className="hide1000">{t('cloak.header.passed')}:</span>{' '}
                                        <strong className="green">{statData.passed}</strong>
                                    </div>
                                    <div>
                                        <span className="hide1000">{t('cloak.header.blocked')}:</span>{' '}
                                        <strong className="red">{statData.blocked}</strong>
                                    </div>
                                    <div>
                                        <span className="hide1000">{t('cloak.header.total')}:</span>{' '}
                                        <strong>{statData.total}</strong>
                                    </div>
                                </div>
                            ) : (
                                ''
                            )}
                            {statData.daysleft ? (
                                <div className="stat_columns">
                                    <div>
                                        <span className="hide1000">{t('cloak.account.daysleft')}:</span>{' '}
                                        <strong>{statData.daysleft}</strong>
                                    </div>
                                </div>
                            ) : (
                                ''
                            )}
                            <HeaderMenu />
                            <button className="btn btn-default burger_menu" onClick={showHideMenu}>
                                {statData.topmenu ? <FontAwesomeIcon icon={faClose} /> : <FontAwesomeIcon icon={faBars} />}
                            </button>
                        </header>
                        <div className={[`under_header`, statData.topmenu ? 'active' : 'notactive'].join(' ')}>
                            <HeaderMenu />
                        </div>
                        <main className="content">
                            <Routes>
                                <Route path="/" element={<StatNew />} />
                                <Route path="/account" element={<Account />} />
                                <Route path="/help" element={<Help />} />
                                <Route path="/cloaks" element={<Cloacks />} />
                                <Route path="/cloak/:id" element={<Cloack />} />
                                <Route path="/changelog" element={<Changelog />} />
                                <Route path="/clicks" element={<Clicks />} />
                                <Route path="/statold" element={<StatOld />} />
                                <Route path="/blacklist" element={<BlackList />} />
                            </Routes>
                        </main>
                        <footer className="footer">
                            <div>
                                Made with &#x1F9E1; in{' '}
                                <a href="https://knkads.com/" target="_blank" rel="noopener noreferrer">
                                    K&K Ads
                                </a>
                            </div>
                            <a href="/statold">{t('cloak.footer.stat.old')}</a>
                            <div>
                                {t('cloak.header.version')} <a href="/changelog">26.03.2025</a>
                            </div>
                            <div className="langs">
                                <button
                                    className={[`btn`, localStorage.getItem('locale') === 'en' ? 'btn-primary' : 'btn-default'].join(' ')}
                                    onClick={changeLang}
                                    data-value="en"
                                >
                                    En
                                </button>
                                <button
                                    className={[`btn`, localStorage.getItem('locale') === 'ru' ? 'btn-primary' : 'btn-default'].join(' ')}
                                    onClick={changeLang}
                                    data-value="ru"
                                >
                                    Ru
                                </button>
                            </div>
                        </footer>
                    </>
                ) : (
                    <LoginPage />
                )}
                <div className={[`loader`, statData.loading ? 'active' : 'notactive'].join(' ')}></div>
            </Router>
        </div>
    );
};

export default App;
