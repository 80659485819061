import { useEffect, useRef, useState } from "react"
import { useDispatch } from "react-redux"
import { t } from "i18next"
import { updateLoader } from "../redux/statSlice"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileExcel, faFloppyDisk } from "@fortawesome/free-solid-svg-icons"

export const BlackList = () => {

    const dispatch = useDispatch()
    const fileInputRef = useRef(null)

    const [blackListText, setBlackListText] = useState("")
    const [userIP, setUserIP] = useState("xxx.xxx.xxx.xxx")
    const [whiteList, setWhiteList] = useState([])

    const uuid = localStorage.getItem("user.id")

    useEffect(() => {
        dispatch(updateLoader(true))
        try {
            fetch(`https://spaceshipbridge.com/ud/api.php?action=getblacklist&user_uuid=${uuid}`)
                .then(response => response.json())
                .then(json => {
                    if (json.blacklist && Array.isArray(json.blacklist)) {
                        setBlackListText(json.blacklist.join('\n'))
                    }
                })
        } catch (error) {
            console.error("Error fetching data:", error)
        } finally {
            dispatch(updateLoader(false))
        }
    }, [dispatch, uuid])

    useEffect(() => {
        dispatch(updateLoader(true))
        try {
            fetch(`https://spaceshipbridge.com/ud/api.php?action=getlocalipinfo&user_uuid=${uuid}`)
                .then(response => response.json())
                .then(json => {
                    json.user_ip && setUserIP(json.user_ip)
                    json.whitelist && setWhiteList(json.whitelist)
                })
        } catch (error) {
            console.error("Error fetching data:", error)
        } finally {
            dispatch(updateLoader(false))
        }
    }, [dispatch, uuid])

    const handleTextareaChange = (e) => {
        setBlackListText(e.target.value)
    }

    const handleUpdate = async () => {
        const ipArray = blackListText
            .split('\n')
            .map(ip => ip.trim())
            .filter(Boolean)

        const payload = {
            action: "updateblacklist",
            user_uuid: uuid,
            blacklist: ipArray
        }

        dispatch(updateLoader(true))

        try {
            const response = await fetch("https://spaceshipbridge.com/ud/api.php", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(payload)
            })

            const result = await response.json()
            console.log("Update result:", result)

        } catch (error) {
            console.error("Update error:", error)
        } finally {
            dispatch(updateLoader(false))
        }
    }

    const handleImportClick = () => {
        fileInputRef.current?.click()
    }

    const handleFileUpload = (e) => {
        const file = e.target.files[0]
        if (!file) return

        const reader = new FileReader()
        reader.onload = (event) => {
            const text = event.target.result
            const importedIPs = text
                .split(/[\n\r,]+/) // разбиваем по строкам и запятым
                .map(ip => ip.trim())
                .filter(Boolean)

            const currentIPs = blackListText
                .split('\n')
                .map(ip => ip.trim())
                .filter(Boolean)

            const all = Array.from(new Set([...currentIPs, ...importedIPs])) // убираем дубли
            setBlackListText(all.join('\n'))
        }

        reader.readAsText(file)
    }

    return (
        <>
            <h1>{t('cloak.blacklist.h1')}</h1>
            <div className="blacklist_columns">

                <textarea
                    className="form-control blacklist_columns_textarea"
                    placeholder={t('cloak.blacklist.blackipkistgoeshere')}
                    value={blackListText}
                    onChange={handleTextareaChange}
                    rows={10}
                />

                <div className="blacklist_columns_stat">

                    <button
                        className="btn btn-primary btn-lg btn-block"
                        onClick={handleUpdate}
                    >
                        <FontAwesomeIcon icon={faFloppyDisk} />
                        {t('cloak.blacklist.update')}
                    </button>

                    <button
                        className="btn btn-info btn-block"
                        onClick={handleImportClick}
                    >
                        <FontAwesomeIcon icon={faFileExcel} />
                        {t('cloak.blacklist.importfromcsv')}
                    </button>

                    <input
                        type="file"
                        accept=".csv,text/csv"
                        ref={fileInputRef}
                        onChange={handleFileUpload}
                        style={{ display: 'none' }}
                    />

                    <div>
                        <div>{t('cloak.blacklist.yourip')}:</div>
                        <div className="blacklist_columns_stat_ip_big">{userIP}</div>
                    </div>

                    <div>
                        <div><strong>{t('cloak.blacklist.whiteiplist')}:</strong></div>
                        <ul className="blacklist_columns_stat_whitelist">
                            {whiteList.map((e, i) => <li key={`whitelistli${i}`}>{e}</li>)}
                        </ul>
                    </div>

                </div>
            </div>
        </>
    )
}
