import { faRobot, faServer, faMobileButton, faGlobe, faLaptopFile, faArrowsRotate, faCopy, faLock, faLaptopCode, faHandcuffs, faUserSecret, faPowerOff, faPersonMilitaryToPerson, faTree, faMobileScreen, faMask, faShieldAlt } from "@fortawesome/free-solid-svg-icons"
import { faApple, faWindows, faAndroid, faGoodreads, faLinux, faChrome } from "@fortawesome/free-brands-svg-icons"

import { t } from 'i18next'

const getIconByName = (name) => {
    switch (name) {
        case 'android':
            return faAndroid;
        case 'ios':
            return faMobileButton;
        case 'windows':
            return faWindows;
        case 'Linux':
            return faLinux;
        case 'mac':
            return faApple;
        case 'bot':
            return faRobot;
        case 'virtual_machine':
            return faServer;
        case 'geo':
            return faGlobe;
        case 'remote_control':
            return faLaptopFile;
        case 'frida':
            return faPersonMilitaryToPerson;
        case 'redirect':
            return faArrowsRotate;
        case 'app_cloners':
            return faCopy;
        case 'privacy_settings':
            return faLock;
        case 'developer_tools':
            return faLaptopCode;
        case 'jailbreak':
            return faHandcuffs;
        case 'incognito':
            return faUserSecret;
        case 'gclid':
            return faGoodreads;
        case 'factory_reset':
            return faPowerOff;
        case 'root_apps':
            return faTree;
        case 'emulator':
            return faMobileScreen;
        case 'tampering':
            return faMask;
        case 'vpn':
            return faShieldAlt;
        case 'chromeos':
            return faChrome;
        default:
            return null;
    }
}

export const smartSignalsRelations = {
    'android': { label: 'Android', icon: getIconByName('android') },
    'mac': { label: 'Mac', icon: getIconByName('mac') },
    'ios': { label: 'iOS', icon: getIconByName('ios') },
    'windows': { label: 'Windows', icon: getIconByName('windows') },
    'linux': { label: 'Linux', icon: getIconByName('Linux') },
    'chromeos': { label: 'Chrome OS', icon: getIconByName('chromeos') },
    'bot': { label: 'Browser Bots', icon: getIconByName('bot') },
    'virtual_machine': { label: 'Virtual Machines', icon: getIconByName('virtual_machine') },
    'geo': { label: 'Geo Spoofing', icon: getIconByName('geo') },
    'remote_control': { label: 'Remote Control', icon: getIconByName('remote_control') },
    'frida': { label: 'Frida', icon: getIconByName('frida') },
    'redirect': { label: 'Redirect / In frame', icon: getIconByName('redirect') },
    'app_cloners': { label: 'Android App Cloners', icon: getIconByName('app_cloners') },
    'privacy_settings': { label: 'Privacy Settings', icon: getIconByName('privacy_settings') },
    'developer_tools': { label: 'Developer Tools', icon: getIconByName('developer_tools') },
    'jailbreak': { label: 'iOS Jailbreak', icon: getIconByName('jailbreak') },
    'incognito': { label: 'Incognito', icon: getIconByName('incognito') },
    'gclid': {
        label: 'Проверка наличия gclid',
        icon: getIconByName('gclid'),
        tip: 'Включите проверку наличия gclid, если хотите, чтобы клоака пропускала только тех пользователей, у которых в UTM-метке указана gclid. Если вы не настроили передачу gclid в настройках рекламного кабинета Google, тогда не нужно включать эту функцию.'
    },
    'factory_reset': { label: 'Mobile Factory Reset', icon: getIconByName('factory_reset') },
    'root_apps': { label: 'Android Root Apps', icon: getIconByName('root_apps') },
    'emulator': { label: 'Android Emulator', icon: getIconByName('emulator') },
    'tampering': { label: 'Browser tampering', icon: getIconByName('tampering') },
    'vpn': { label: 'VPN', icon: getIconByName('vpn') },
}

export const getDomainFromURL = (url) => {
    try {
        if (typeof url === 'string' && url.length > 0) {
            const parsedURL = new URL(url);
            return parsedURL.hostname;
        }
        return null;
    } catch (error) {
        return null;
    }
};
export const formatDateTime = dateTimeString => {
    const date = new Date(dateTimeString);

    // Получаем текущие дату и время
    const now = new Date();

    // Получаем разницу во времени (в миллисекундах)
    const differenceInTime = now - date;

    // Время для одного дня в миллисекундах
    const oneDay = 24 * 60 * 60 * 1000;

    // Форматирование времени HH:MM:SS
    const timeString = date.toTimeString().split(' ')[0];

    // Получаем день, месяц и год
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();

    // Если это сегодняшняя дата
    if (differenceInTime < oneDay && date.getDate() === now.getDate()) {
        return `${t('cloak.dates.today')} ${timeString}`;
    }

    // Если это вчерашняя дата
    if (differenceInTime < 2 * oneDay && now.getDate() - date.getDate() === 1) {
        return `${t('cloak.dates.yesterday')} ${timeString}`;
    }

    // Если это в текущем году, но не вчера и не сегодня
    if (year === now.getFullYear()) {
        return `${day} ${month} ${timeString}`;
    }

    // Если это в прошлом году
    return `${day} ${month} ${year} ${timeString}`;
}
export const formatDate = dateString => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB', {
        day: '2-digit',
        month: 'short',
        year: 'numeric'
    })
}
export const daysUntil = (dateString) => {
    const targetDate = new Date(dateString)
    const currentDate = new Date()
    if (isNaN(targetDate.getTime())) {
        return 'Invalid date'
    }
    const timeDiff = targetDate - currentDate
    return Math.ceil(timeDiff / (1000 * 60 * 60 * 24)) || 0;
}

export const _daysUntil = (expirationDate) => {
    const now = new Date()
    const expiry = new Date(expirationDate)
    const diffTime = expiry - now
    if (diffTime <= 0) return false
    return Math.floor(diffTime / (1000 * 60 * 60 * 24))
}

export const getTimezoneOffset = (tz) => {
    try {
        const now = new Date();
        const formatter = new Intl.DateTimeFormat("en-US", {
            timeZone: tz,
            timeZoneName: "shortOffset",
        });
        const parts = formatter.formatToParts(now);
        const offsetPart = parts.find((part) => part.type === "timeZoneName");
        return offsetPart ? offsetPart.value.replace("UTC", "") : "";
    } catch (e) {
        return ""
    }
}
export const formatLocalizedDate = (timestamp, timezone) => {
    if (!timestamp) return "Invalid date";

    const locale = localStorage.getItem("locale") || "en"; // Дефолтная локаль "en"
    const now = new Date();
    const date = new Date(timestamp); // Преобразуем timestamp в Date

    // Получаем дату с учетом часового пояса (только время)
    const timeFormatter = new Intl.DateTimeFormat(locale, {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        timeZone: timezone,
        hour12: false, // Убираем AM/PM, чтобы всегда было в 24-часовом формате
    });

    const formattedTime = timeFormatter.format(date); // Только время (без даты)

    // Получаем полную дату с учетом часового пояса
    const fullDateFormatter = new Intl.DateTimeFormat(locale, {
        year: "numeric",
        month: "long",
        day: "numeric",
        timeZone: timezone,
    });

    let formattedDate = fullDateFormatter.format(date); // Полная дата (без времени)

    // Определяем, совпадает ли год с текущим
    const isSameYear = now.getFullYear() === date.getFullYear();

    // Определяем разницу дней между датами
    const nowStartOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate()).getTime();
    const dateStartOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate()).getTime();
    const diffDays = Math.round((dateStartOfDay - nowStartOfDay) / (1000 * 60 * 60 * 24));

    // Определяем "Вчера", "Сегодня", "Завтра"
    if (diffDays === -1) {
        return locale === "ru" ? `Вчера ${formattedTime}` : `Yesterday ${formattedTime}`;
    }
    if (diffDays === 0) {
        return locale === "ru" ? `Сегодня ${formattedTime}` : `Today ${formattedTime}`;
    }
    if (diffDays === 1) {
        return locale === "ru" ? `Завтра ${formattedTime}` : `Tomorrow ${formattedTime}`;
    }

    // Если год совпадает, убираем его
    if (isSameYear) {
        return `${formattedDate} ${formattedTime}`;
    }

    return `${formattedDate} ${date.getFullYear()} ${formattedTime}`;
}

export const GridTable = ({ rows, columns }) => {
    return (
        <div className="grid-container" style={{ gridTemplateColumns: `repeat(${columns}, 1fr)` }}>
            {Array.from({ length: rows * columns }).map((_, index) => (
                <div key={index} className="grid-item"></div>
            ))}
        </div>
    )
}