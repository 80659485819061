import { HelpRu } from "./HelpRu"
import { HelpEn } from "./HelpEn"

export const Help = () => {

    const HelpComponent = localStorage.getItem("locale") === "en" ? HelpEn : HelpRu

    return (
        <>
            <HelpComponent />
        </>
    )
}